<template>
  <div>
    <header-bar :title="$t('title')" :inverted="true"></header-bar>
    <q-page style="padding-top: 50px">
      <q-card class="q-py-xl q-px-sm jumbotron">
        <div class="row justify-center">
          <div class="col-12 col-sm-8">
            <div v-if="!(loaded || error)" class="text-center text-white">
              <h4 class="q-ma-none">{{ platform.name }} {{ $t('homepage_text') }}</h4>
              <p class="q-mt-md">{{ $t('homepage_subtext', { platform: platform.description }) }}</p>
            </div>

            <q-card-section>
              <q-form @submit.prevent="fetchMedia">
              <q-input dark standout v-model="sourceLink" :loading="loading" :autofocus="true" :placeholder="$t('paste_link_here')">
                  <template v-slot:append>
                    <q-icon v-if="sourceLink === ''" name="link" />
                    <span v-else>
                      <q-btn v-if="!loading" @click="fetchMedia" color="primary" :label="$t('download')" />
                    </span>
                  </template>
                </q-input>
              </q-form>
              <br />
              <p class="text-white">Example: <strong>{{ platform.example }}</strong></p>
            </q-card-section>

            <q-card-section class="q-mt-xl">
              <i18n-t keypath="accept_terms_of_service" tag="p" class="text-center text-white">
                <template #terms_of_service>
                  <a href="#" @click.prevent="showTermsOfService = true">
                    {{ $t('terms_of_service') }}
                  </a>
                </template>
              </i18n-t>
            </q-card-section>
          </div>
        </div>
      </q-card>

      <br />

      <div v-if="loaded" class="row justify-center">

        <div v-if="error" class="row justify-center text-center q-mt-xl">
          <p class="text-h6 text-weight-bold text-negative">{{ error }}</p>
        </div>

        <div v-else class="col-xs-12 col-sm-5">
          <div class="q-pl-lg q-pr-lg">
            <q-img :ratio="4/3" :src="mediaData.screenshot_link" loading="lazy" />
          </div>

          <q-card-section>
            <q-item-label lines="3" style="font-size: 17px;">
              {{ mediaData.name }}
            </q-item-label>

            <br />

            <router-link
              :to="introRouteParams"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate" style="text-decoration: none;"><q-btn color="primary" size="lg" label="Want to save your downloads history?" no-caps outline class="full-width"></q-btn></a>
            </router-link>
            <br /><br />
            <q-btn @click="downloadMedia(mediaData)" color="primary" label="Download" size="lg" no-caps class="full-width" />
          </q-card-section>
        </div>

        <div id="ad-section" class="col-xs-12 col-sm-4" :class="$q.screen.lt.sm ? 'order-first': 'order-second'">
          <component
          src="https://tg1.aniview.com/api/adserver/spt?AV_TAGID=632c10157c23b1265d719e68&AV_PUBLISHERID=632c0e58569c1006c10c9675"
            :is="script"
            id="AV632c10157c23b1265d719e68"
            type="text/javascript"
            async
          >
          </component>
        </div>
      </div>

      <div class="q-pl-lg q-pr-lg" v-else>
        <h4 class="text-center">FAQS</h4>

        <div class="row q-col-gutter-xl">

          <div class="col-sm-12 col-md-4">
            <p class="text-h6 text-bold">How to Download {{ platform.name }} videos</p>

            <p>
              <ol>
                <li>Open the {{ platform.name }} app on your phone or Web on your browser.</li>
                <li>Choose whatever video you want to download.</li>
                <li>Click to the Share button at the right bottom.</li>
                <li>Click the Copy Link button.</li>
                Download by using your browsers: No need to download or install any software.
                <li>Go  to videodownloaderbot.com and paste your download link to the field above then click to the Download button.
                Wait for our server to do its job and then, save the video to your device.</li>
              </ol>
            </p>
          </div>

          <div class="col-sm-12 col-md-4">
            <p class="text-h6 text-bold">Where are my {{ platform.name }} videos saved after being downloaded?</p>
            When you're downloading files, they are usually saved into whatever folder you have set as your default.
            Your browser normally sets this folder for you.
            In browser settings, you can change and choose manually the destination folder for your downloaded {{ platform.name }} videos.

            All Videos downloaded are downloaded via {{ platform.name }}'s servers, no copies are kept by us .
            For ease of access and having your vidoes in one place, You can always access your download history on your useraccount which is here.
          </div>

          <div class="col-sm-12 col-md-4">
            <p class="text-h6 text-bold">Do I need to install instructions or extensions?</p>
            No app or additonal extension is needed to use this service. All you need is a link from {{ platform.name }}.
          </div>

          <div class="col-sm-12 col-md-4">
            <p class="text-h6 text-bold">Do I have to pay to use Video Downloader Bot ?</p>

            This service is Totally Free to use
          </div>

          <div class="col-sm-12 col-md-4">
            <p class="text-h6 text-bold">Can I use this {{ platform.name }} video downloader on my Android phone?</p>

            Yes, It's super Easy to use
          </div>

          <div class="col-sm-12 col-md-4">
            <p class="text-h6 text-bold">Can  I save videos / download {{ platform.name }} mp4 videos to my iPhone/Ipad Device  (IOS)?</p>

            Yes, Follow this Esy steps to use Videodownloaderbot to download your favorite {{ platform.name }} video to your Iphone/Ipad
          </div>
        </div>

      </div>

      <q-dialog v-model="showTermsOfService">
        <q-card>
          <q-card-section>
            <div class="text-h6">Terms of Service</div>
          </q-card-section>

          <q-separator />

          <q-card-section style="max-height: 50vh" class="scroll">
            <span v-html="termsOfServiceHtml"></span>
          </q-card-section>

          <q-separator />

          <q-card-actions align="right">
            <q-btn flat label="Close" color="primary" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </q-page>
  </div>
</template>

<script>

/* global gtag */

import { fetchMediaUsingSource } from '../download_sources'
import HeaderBar from '../components/HeaderBar'
import { getTermsOfServiceHtml } from '../api'
import Intro from './Intro.vue'
// import { createMetaMixin } from 'quasar'

export default {
  name: 'Public',

  components: { HeaderBar },

  data () {
    return {
      script: 'script',
      showTermsOfService: false,
      termsOfServiceHtml: '',
      sourceLink: '',
      downloadSource: null,
      mediaData: {},
      error: '',
      loaded: false,
      loading: false,
      platforms: {
        DEFAULT: {
          name: '',
          example: 'https://twitter.com/txiokatu/status/1537852176525139971',
          description: 'Tiktok, Twitter and other websites'
        },
        TWITTER: {
          name: 'Twitter',
          example: 'https://twitter.com/BigDawsTv/status/1537500166281760768',
          description: 'Twitter'
        },
        TIKTOK: {
          name: 'TikTok',
          example: 'https://www.tiktok.com/@edsheeran/video/7109127109469064453',
          description: 'TikTok'
        },
        FACEBOOK: {
          name: 'Facebook',
          example: 'https://www.facebook.com/fcbarcelona/videos/messi-skills/703687470107141/',
          description: 'Facebook'
        },
        YOUTUBE: {
          name: 'YouTube',
          example: 'https://www.youtube.com/watch?v=Mp_aIHXzPoM',
          description: 'YouTube'
        }
      }
    }
  },

  computed: {
    introRouteParams () {
      return { name: Intro.name, params: { language: this.$route.params.language } }
    },
    platform () {
      const platform = this.$route.meta.platform
      return this.platforms[platform] || this.platforms['DEFAULT']
    }
  },

  methods: {

    fetchMedia () {
      this.loading = true

      function handler ({ error, DownloadSource, media }) {
        if (error) {
          this.error = error
        }

        if (Object.keys(media).length > 0) {
          this.downloadSource = DownloadSource
          this.mediaData = media
        }
        this.loading = false
        this.loaded = true
      }

      fetchMediaUsingSource(this, this.sourceLink, handler.bind(this))
    },

    downloadMedia (mediaData) {
      gtag('event', 'download_media_home_page', {
        'event_category': 'public_user_actions',
        'event_label': mediaData.source_link,
        value: 1
      })

      this.downloadSource.downloadMedia(mediaData)
    }
  },

  mounted () {
    // Fetch terms of service html from the server
    getTermsOfServiceHtml().then(response => {
      this.termsOfServiceHtml = response.data
    })
  }
}
</script>

<style scoped>

h5 {
  margin: 0;
}

.jumbotron {
  background-image: linear-gradient(to right, #1976d2, #1976e2,#1976f2);
  border-radius: 0;
}

.q-btn {
  font-weight: normal
}
</style>
